import { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-async";
import { LOS_GRID_EVENT } from "../Lit/acc-wc-views/src/acc-wc-view-los-indented/enum.js";
import { mapAttributes, regionalConfigPath, storedIsoCountry } from "../../common/util/config";
import { fetchContent, storedLocale } from "../impersonation/util";
import { useAppDispatch, useAppSelector } from "../../store";
import ABOProfile from "../ABOProfile/ABOProfile";
import { showProfile } from "../../reducers/profile";
import { MODULE_WATCHLIST } from "../../common/constants/export-constants";
import { ReportExportModal } from "../ServerSideExport/ReportExportModal/ReportExportModal";
import ServerSideExportCompletedNotify from "../ServerSideExport/ServerSideExportCommon/ServerSideExportNotify/ServerSideExportCompletedNotify/ServerSideExportCompletedNotify";
import ServerSideExportInProgressNotify from "../ServerSideExport/ServerSideExportCommon/ServerSideExportNotify/ServerSideExportInProgressNotify/ServerSideExportInProgressNotify";
import { OpenExportV2ModalEvent } from "../ServerSideExport/ServerSideExportCommon/ServerSideExportUtil/ServerSideExportEvents";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import "./Watchlist.scss";
import "../ComponentLoader/ComponentLoader.scss";

const loadComponent = async () => {
  import("../Lit/acc-wc-los-ar/acc-wc-los-ar.js");
};

export default function Watchlist() {
  const { configuration } = useAppSelector((state) => state.boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const locale = storedLocale();
  window.accConfigurationPath = regionalConfigPath();
  const isCountryCode = storedIsoCountry();
  const pageId = "watchlist";
  const { isPending } = useAsync({ promiseFn: loadComponent });
  const dispatch = useAppDispatch();
  const [modalOpenedByElement, setModalOpenedByElement] = useState<HTMLElement>();
  const [openExportModal, setExportModalState] = useState(false);

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  const updatedWatchlist = useMemo(() => {
    return {
      ...configuration.watchlist,
      uiGrid: (configuration.watchlist.uiGrid || "uiMenuShowProfile,uiMenuShowPerfHistory,uiMenuShowWatchlist")
        .split(",")
        .filter((item) => item !== "uiMenuShowPerfHistory")
        .join(","),
    };
  }, [configuration.watchlist]);

  useEffect(() => {
    if (!isPending) {
      const watchlist = document.createElement("acc-wc-los-ar");
      watchlist.setAttribute("locale", locale.toLowerCase());
      watchlist.setAttribute("id", "acc_wc_view_watchlist_component");
      watchlist.setAttribute("period", selectedPeriod);
      watchlist.setAttribute("enableExportV2", "true");

      // Get market config for market-specific properties and content
      fetchContent(pageId, isCountryCode, locale, {}).then((content) => {
        mapAttributes(watchlist, { ...updatedWatchlist, ...{ disclaimer: content.footerDisclaimerMD || "" } }, {});

        // After all properties are added, render!
        const wrapper = document.getElementById("watchlist-wrapper") as HTMLDivElement;
        const watchlistElements = document.getElementsByTagName("acc-wc-los-ar");
        // prevent multiple watchlist instances being appended to the DOM
        if (wrapper && watchlist && !watchlistElements.length) {
          wrapper.append(watchlist);
        }
      });
    }
  }, [isPending, updatedWatchlist, locale, isCountryCode, selectedPeriod]);

  const showABOProfile = useCallback(
    (event: CustomEventInit) => {
      dispatch(showProfile(event.detail.abo.affAbo));
      setModalOpenedByElement(event.detail.target);
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    return () => {
      window.removeEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    };
  }, [showABOProfile]);

  const showExportModal = useCallback((event: CustomEventInit) => {
    setExportModalState(Boolean(event.detail?.open));
  }, []);

  useEffect(() => {
    window.addEventListener(OpenExportV2ModalEvent, showExportModal);
    return () => {
      window.removeEventListener(OpenExportV2ModalEvent, showExportModal);
    };
  }, [showExportModal]);

  const notifyDownloadLinkOnClick = () => {
    setExportModalState(true);
  };

  const closeExportModal = () => {
    setExportModalState(false);
  };

  if (isPending) {
    return <div className="Watchlist__wrapper"></div>;
  }

  return (
    <main>
      <div id="watchlist-wrapper" className="Watchlist__wrapper"></div>
      <ABOProfile previousElement={modalOpenedByElement} />
      <BonusInfoFooter hideDate />
      <ServerSideExportInProgressNotify />
      <ServerSideExportCompletedNotify
        moduleId={MODULE_WATCHLIST}
        notifyDownloadLinkOnClick={notifyDownloadLinkOnClick}
      />
      <ReportExportModal moduleId={MODULE_WATCHLIST} open={openExportModal} onCloseCallbackFn={closeExportModal} />
    </main>
  );
}
